/* eslint-disable camelcase */
import axios from 'axios';
import objectToFormData from '../utils/objectToFormData';
import objectToSnakeCasedKeys from '../utils/objectToSnakeCasedKeys';
import { API } from '../constants';
import { apiUrl } from './lt-api';

axios.defaults.baseURL = API;

const defaultHeaders = {
  crossDomain: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    withCredentials: true,
  },
  method: 'get',
};

export function getTableData(params) {
  const api = apiUrl.slice(0, -1);
  return axios({
    ...defaultHeaders,
    url: `${api}${params}`,
  });
}

export function getFilterData() {
  return axios({
    ...defaultHeaders,
    url: `${apiUrl}products/filter_options`,
  });
}

export function getProductData(id) {
  return axios({
    ...defaultHeaders,
    url: `${apiUrl}products/${id}`,
  });
}

export function getEtlProductData(id) {
  return axios({
    ...defaultHeaders,
    url: `${apiUrl}etl/products/${id}`,
  });
}

export function getProductDataClone(id) {
  return axios({
    ...defaultHeaders,
    url: `${apiUrl}products/${id}/clone`,
  });
}

export function getProductOptionsData() {
  return axios({
    ...defaultHeaders,
    url: `${apiUrl}products/product_options`,
  });
}

export function getFurnitureData(id) {
  return axios({
    ...defaultHeaders,
    url: `${API}/furniture/${id}`,
  });
}

export function updateProductData(id, payload) {
  const formData = objectToFormData(payload);
  return axios({
    ...defaultHeaders,
    data: formData,
    method: 'PUT',
    url: `${apiUrl}products/${id}`,
  });
}

export function updateStockStatusProductData(id) {
  return axios({
    ...defaultHeaders,
    url: `${apiUrl}products/${id}/stock_status_update`,
  });
}

export function createProductData(payload) {
  const formData = objectToFormData(payload);
  return axios({
    ...defaultHeaders,
    data: formData,
    method: 'POST',
    url: `${apiUrl}products`,
  });
}

export function getScraperProductByUrl(etl_product_sku, supplier_id) {
  return axios({
    ...defaultHeaders,
    data: {
      etl_product_sku,
      remote_supplier_id: supplier_id,
    },
    method: 'POST',
    url: `${API}etl/scrapers/product/create`,
  });
}

export function getScraperManyProductsByUrl(etl_product_sku, supplier_id) {
  return axios({
    ...defaultHeaders,
    data: {
      etl_product_sku,
      remote_supplier_id: supplier_id,
    },
    method: 'POST',
    url: `${API}etl/scrapers/product/checker`,
  });
}

export function updateItemsBySearch(params) {
  return axios({
    ...defaultHeaders,
    params: objectToSnakeCasedKeys(params),
    url: `${API}/products`,
  });
}

export function deleteItemsRequest(url) {
  const api = apiUrl.slice(0, -1);
  return axios({
    ...defaultHeaders,
    method: 'delete',
    url: `${api + url}`,
  });
}

export function assignCategoryToItemsRequest(url) {
  const api = apiUrl.slice(0, -1);
  return axios({
    ...defaultHeaders,
    method: 'post',
    url: `${api + url}`,
  });
}

export function moveItemsRequest(url) {
  const api = apiUrl.slice(0, -1);
  return axios({
    ...defaultHeaders,
    method: 'post',
    url: `${api + url}`,
  });
}

export function getRoomData(id) {
  return axios({
    ...defaultHeaders,
    url: `${apiUrl}rooms/${id}`,
  });
}

export function getRoomVariantData(variantId) {
  return axios({
    ...defaultHeaders,
    url: `${apiUrl}room_variants/${variantId}`,
  });
}

export function getRoomOptionsData() {
  return axios({
    ...defaultHeaders,
    url: `${apiUrl}rooms/room_options`,
  });
}

export function updateRoomData(id, payload) {
  const formData = objectToFormData(payload);
  return axios({
    ...defaultHeaders,
    data: formData,
    method: 'PUT',
    url: `${apiUrl}rooms/${id}`,
  });
}

export function createRoomData(payload) {
  const formData = objectToFormData(payload);
  return axios({
    ...defaultHeaders,
    data: formData,
    method: 'POST',
    url: `${apiUrl}rooms`,
  });
}

export function cloneRoomData(id) {
  return axios({
    ...defaultHeaders,
    method: 'POST',
    url: `${apiUrl}rooms/${id}/clone`,
  });
}

export function createRoomVariantData(id, payload) {
  const formData = objectToFormData(payload);
  return axios({
    ...defaultHeaders,
    data: formData,
    method: 'POST',
    url: `${apiUrl}rooms/${id}/room_variants/create`,
  });
}

export function updateRoomVariantData(id, payload) {
  const formData = objectToFormData(payload);
  return axios({
    ...defaultHeaders,
    data: formData,
    method: 'PUT',
    url: `${apiUrl}room_variants/${id}`,
  });
}

export function addProductsToVariantData(payload) {
  const { products, vid, design_room_id } = payload;
  const formData = objectToFormData({ design_room_id, products });
  return axios({
    ...defaultHeaders,
    data: formData,
    method: 'POST',
    url: `${apiUrl}room_variants/${vid}/add_product`,
  });
}

export function moveProductsDesignRoomVariant(payload) {
  const {
    variant_id, room_id, design_room_id, from_design_room_id, furniture_ids,
  } = payload;
  const formData = objectToFormData({
    design_room_id, from_design_room_id, furniture_ids, room_id,
  });
  return axios({
    ...defaultHeaders,
    data: formData,
    method: 'POST',
    url: `${apiUrl}room_variants/${variant_id}/move_products`,
  });
}

export function getListRoomVariants(payload) {
  const { query } = payload;
  const formData = objectToFormData({ query });

  return axios({
    ...defaultHeaders,
    data: formData,
    method: 'POST',
    url: `${apiUrl}rooms/room_variants`,
  });
}

export function calculateVariant(id) {
  return axios({
    ...defaultHeaders,
    method: 'GET',
    url: `${apiUrl}room_variants/${id}/calculate_price`,
  });
}

export function deleteRoomData(id) {
  const formData = objectToFormData({ id });

  return axios({
    ...defaultHeaders,
    data: formData,
    method: 'DELETE',
    url: `${apiUrl}rooms/${id}`,
  });
}

export function deleteRoomVariantData(id) {
  const formData = objectToFormData({ id });
  return axios({
    ...defaultHeaders,
    data: formData,
    method: 'DELETE',
    url: `${apiUrl}room_variants/${id}`,
  });
}

export function uploadExcel({ file, email }) {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('email', email);

  return axios({
    ...defaultHeaders,
    data: formData,
    method: 'POST',
    url: `${apiUrl}products_import/import`,
  });
}

export function getLogsData() {
  return axios({
    ...defaultHeaders,
    method: 'GET',
    url: `${apiUrl}products_import/logs`,
  });
}

export function getLogsDataStatusReport(params) {
  return axios({
    ...defaultHeaders,
    method: 'GET',
    url: `${apiUrl}products_logs${params}`,
  });
}

export function getAllCategories() {
  return axios({
    ...defaultHeaders,
    method: 'GET',
    url: `${apiUrl}menu/items`,
  });
}

export function cloneRoomVariant(variantId) {
  return axios({
    ...defaultHeaders,
    method: 'GET',
    url: `${apiUrl}room_variants/${variantId}/clone`,
  });
}
