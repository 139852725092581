import { all } from 'redux-saga/effects';
import { saga as tableSaga } from '../ducks/products';
import { saga as productsETLSaga } from '../ducks/etl_products';
import { saga as filterData } from '../ducks/filter';
import { saga as etlFilterData } from '../ducks/etl_filter';
import { saga as filterETLData } from '../ducks/etl_product';
import { saga as productData } from '../ducks/product';
import { saga as productETLData } from '../ducks/etl_product';
import { saga as tagData } from '../ducks/tag';
import { saga as excelProductsData } from '../ducks/productMany';
import { saga as categoriesData } from '../ducks/categories';
import { saga as roomData } from '../ducks/room';
import { saga as productLtSaga } from '../ducks/productsLT';
import { saga as categoryRules } from '../ducks/categoryRules';
import { saga as selectorSaga } from '../ducks/selector';
import { saga as paginationSaga } from '../ducks/pagination';
import { saga as filtersSaga } from '../ducks/filters';
import { saga as searchInputSaga } from '../ducks/searchInput';
import { saga as tableSelectedSaga } from '../ducks/table';
import { saga as attributeSaga } from '../ducks/attribute';
import { saga as ruleSaga } from '../ducks/rule';
import { saga as copyFurnitureItemsSaga } from '../ducks/copyFurnitureItems';
import { saga as moveDesignRoomSaga } from '../ducks/moveDesignRoom';
import { saga as tagsData } from '../ducks/tags';
import { saga as inventoriesData } from '../ducks/inventories';
import { saga as categoriesMappingData } from '../ducks/categoriesMapping';
import { saga as fileUploaderSaga } from '../ducks/fileUploader';
import { saga as availableColorsSaga } from '../ducks/availableColors';
import { saga as availableTexturesSaga } from '../ducks/availableTextures';
import { saga as availableRulesSaga } from '../ducks/availableRules';
import { saga as availableFloorPlansSaga } from '../ducks/availableFloorPlans';
import { saga as availableDesignStylesSaga } from '../ducks/availableDesignStyles';
import { saga as roomsSaga } from '../ducks/rooms';
import { saga as availableTemplatesSaga } from '../ducks/availableTemplates';
import { saga as availableScrapersSaga } from '../ducks/availableScrapers';
import { saga as availableInventorySuppliersSaga } from '../ducks/availableInventorySuppliers';
import { saga as availableCategoriesSaga } from '../ducks/availableCategories';
import { saga as authSaga } from '../ducks/auth';
import { saga as routerSaga } from '../ducks/router';
import { saga as designRuleMatchingSaga } from '../ducks/designRuleMatching';
import { saga as dataStatusReportSaga } from '../ducks/dataStatusReport';
import { saga as deliveryAgentsSaga } from '../ducks/deliveryAgents';
import { saga as usersSaga } from '../ducks/users';
import { saga as suppliersSaga } from '../ducks/suppliers';

// all sagas connector
export default function* sagas() {
  yield all([
    suppliersSaga(),
    tableSaga(),
    filterData(),
    etlFilterData(),
    usersSaga(),
    filterETLData(),
    productData(),
    deliveryAgentsSaga(),
    productETLData(),
    tagData(),
    roomData(),
    dataStatusReportSaga(),
    excelProductsData(),
    categoriesData(),
    productLtSaga(),
    categoryRules(),
    productsETLSaga(),
    selectorSaga(),
    paginationSaga(),
    filtersSaga(),
    searchInputSaga(),
    tableSelectedSaga(),
    attributeSaga(),
    ruleSaga(),
    copyFurnitureItemsSaga(),
    moveDesignRoomSaga(),
    tagsData(),
    inventoriesData(),
    categoriesMappingData(),
    fileUploaderSaga(),
    availableColorsSaga(),
    availableTexturesSaga(),
    availableRulesSaga(),
    availableFloorPlansSaga(),
    availableDesignStylesSaga(),
    availableTemplatesSaga(),
    availableScrapersSaga(),
    availableInventorySuppliersSaga(),
    roomsSaga(),
    authSaga(),
    routerSaga(),
    availableCategoriesSaga(),
    designRuleMatchingSaga(),
  ]);
}
