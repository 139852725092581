import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _set from 'lodash/fp/set';
import _get from 'lodash/fp/get';
// Redux
import { connect } from 'react-redux';
import Switch from 'react-switch';
import {
  createRoomTypeAction,
  getFurnitureCategoriesSelector,
  makeAddFurnitureCategoriesSelector,
} from '../../../../ducks/availableCategories';
// Components
import { Button } from '../../../Shared';
import { CreateFormStyle } from './style';
import Table from './Table';
import objectToFormData from '../../../../utils/objectToFormData';
import DropZone from '../DropZone';

class CreateForm extends Component {
  static propTypes = {
    fetchFurnitureCategories: PropTypes.func.isRequired,
    furnitureCategories: PropTypes.array.isRequired,
    addFurnitureCategories: PropTypes.array,
  };

  state = {
    furnitureCategory: {
      value: '',
      error: null,
    },
    is_public: false,
    icon_url: [],
    categories: {},
    removeCategoryIds: [],
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.addFurnitureCategories !== this.props.addFurnitureCategories) {
      this.initializeCategories();
    }
  }

  // Categories
  initializeCategories = () => {
    const { addFurnitureCategories } = this.props;

    const categories = addFurnitureCategories.reduce((acc, category) => {
      if (category.children.length) {
        category.children.forEach((item) => {
          acc[item.value] = {
            product_category_id: item.value,
            quantity: _get(`${[item.value]}.quantity`, this.state.categories) || 0,
            average_cost: _get(`${[item.value]}.average_cost`, this.state.categories) || item.average_cost || 0,
            variation: _get(`${[item.value]}.variation`, this.state.categories) || 'not_set',
          };
        });
      }

      return acc;
    }, {});

    this.setState({ categories });
  };

  onChangeCategories = (idCategory, event) => {
    const { target } = event;
    const { name, value } = target;

    this.setState((prevState) => ({
      categories: {
        ...prevState.categories,
        [idCategory]: _set(`${name}`, value, prevState.categories[idCategory]),
      },
    }));
  };

  onChangeSelectCategories = (idCategory, selected) => {
    const { value } = selected;

    this.setState((prevState) => ({
      categories: {
        ...prevState.categories,
        [idCategory]: _set('variation', value, prevState.categories[idCategory]),
      },
    }));
  };

  onChangeRemoveCategory = (event) => {
    const { target } = event;
    const { name, checked } = target;

    this.setState((prevState) => ({
      removeCategoryIds: {
        ...prevState.removeCategoryIds,
        [name]: checked,
      },
    }));
  };
  // End Category

  // Submit form
  formOnSubmit = (event) => {
    event && event.preventDefault && event.preventDefault();
    const {
      furnitureCategory, categories, icon_url, is_public,
    } = this.state;

    if (!this.disabledSubmit()) {
      const data = {
        design_room: {
          name: furnitureCategory.value,
          icon_url: icon_url[0],
          is_public,
          style_design_categories_attributes: Object.values(categories).length ? [...Object.values(categories)] : [[]],
        },
      };

      this.props.createRoomType(objectToFormData(data));
    }
  };

  disabledSubmit = () => !this.state.furnitureCategory.value;
  // End Submit form

  // Input "Room name"
  inputOnChange = ({ target }) => {
    this.setState((prevState) => ({
      [target.name]: {
        ...prevState[target.name],
        value: target.value,
      },
    }));
  };

  inputOnFocus = ({ target }) => {
    this.setState((prevState) => ({
      [target.name]: {
        ...prevState[target.name],
        error: null,
      },
    }));
  };

  inputOnBlur = ({ target }) => {
    if (!target.value) {
      this.setState((prevState) => ({
        [target.name]: {
          ...prevState[target.name],
          error: 'This field is required',
        },
      }));
    }
  };
  // End Input "Room name"

  // Switch "Is public"
  onSwitchChange = () => this.setState((prevState) => ({ is_public: !prevState.is_public }));
  // End switch

  // FileUploader "Is public"
  onFileUploaderChange = (files) => this.setState({ icon_url: files });

  // End FileUploader

  render() {
    const { furnitureCategory: { value, error } } = this.state;
    const inputLabelClassNames = cn('form-control__label', { 'error': !!error });
    const inputClassNames = cn('form-control__input', { 'error': !!error });

    return (
      <CreateFormStyle>
        <form noValidate className="form" onSubmit={this.formOnSubmit}>
          <div className="form-control__wrapper">
            <label htmlFor="furnitureCategory-id" className={inputLabelClassNames}>Room name</label>
            <div className="form-control__input-wrapper">
              <input
                className={inputClassNames}
                name="furnitureCategory"
                id="furnitureCategory-id"
                type="text"
                value={value}
                onChange={this.inputOnChange}
                onFocus={this.inputOnFocus}
                onBlur={this.inputOnBlur}
              />
              {error && <span className="form-control__error">{error}</span>}
            </div>
          </div>
          <div className="form-control__row">
            <div className="form-control__switch-wrapper">
              <p className="form-control__label no-padding">Is public</p>
              <Switch onChange={this.onSwitchChange} checked={this.state.is_public} />
            </div>
            <div className="form-control__file-uploader-wrapper">
              <DropZone fileUrl={this.state.icon_url[0]} onFileUploaderChange={this.onFileUploaderChange} />
            </div>
          </div>
          <div className="form__table">
            <Table
              data={this.props.furnitureCategories}
              onChangeCategory={this.onChangeRemoveCategory}
              onChangeCategories={this.onChangeCategories}
              onChangeSelectCategories={this.onChangeSelectCategories}
              categories={this.state.categories}
            />
          </div>
          <div className="form__button-wrapper">
            <Button
              type="submit"
              uppercase
              size="large"
              width={150}
              disabled={this.disabledSubmit()}
            >
              Save
            </Button>
          </div>
        </form>
      </CreateFormStyle>
    );
  }
}

const mapStateToProps = (state) => {
  const addFurnitureCategoriesSelector = makeAddFurnitureCategoriesSelector();

  return {
    furnitureCategories: getFurnitureCategoriesSelector(state),
    addFurnitureCategories: addFurnitureCategoriesSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  createRoomType: (roomData) => dispatch(createRoomTypeAction(roomData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateForm);
