import React, { Component, Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import {
  AddProduct,
  AttributeTagging,
  CategoryRules,
  Auth,
  CloneProduct,
  DataStatusReport,
  DesignTemplates,
  DesignTemplatesDetail,
  DeliveryAgent,
  ImportProducts,
  MergePurge,
  Product,
  ProductCategories,
  Products,
  Room,
  Rooms,
  Tags,
  Tag,
  DeliveryAgentView,
  Suppliers,
  SupplierView,
} from '../Pages';
import {
  ColorsDetail,
  DesignRulesDetail,
  DesignStylesDetail,
  DesignTemplatesCategoriesDetail,
  FloorPlansDetail,
  TemplatesDetail,
  TexturesDetail,
} from '../Components/DesignTemplates/index';
import Navigation from '../Components/Navigation';
import DefaultModal from '../Components/Shared/Modal';
import { ProtectedRoute } from '../Components/Shared';

import { categoriesSelector, getCategories, loadingCategoriesSelector } from '../ducks/categories';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import ETL from '../Pages/ETL';
import {
  Scrapers,
  InventoryFiles,
  ScraperProducts,
  ScraperProduct,
  ScraperMapCategories,
  Inventories,
  Inventory,
} from '../Components/ETL/index';

Modal.setAppElement('#root');

export class App extends Component {
  componentDidMount() {
    this.props.getCategories();
  }

  render() {
    const { loading } = this.props;

    return (
      <article className="furnishr__main">
        <Navigation loading={loading} profile={this.props.admin} categories={this.props.categories} />
        <DefaultModal />
        <section className="furnishr__main-cont">
          <Suspense fallback={<div>Loading...</div>}>
            <Route exact path="/rooms" component={Rooms} />
            <Route exact path="/rooms/:id/variant/:vid/add-product" component={AddProduct} />
            <Route exact path="/products" component={Products} />
            <Route exact path="/delivery-agents" component={DeliveryAgent} />
            <Route exact path="/product-categories" component={ProductCategories} />
            <Route exact path="/login" component={Auth} />
            <Route exact path="/attribute-tagging" component={AttributeTagging} />
            <Route path="/merge-purge" component={MergePurge} />
            <Route exact path="/suppliers" component={Suppliers} />
            <Switch>
              <ProtectedRoute exact path="/suppliers/:id" component={SupplierView} />
              <ProtectedRoute exact path="/delivery-agents/:id" component={DeliveryAgentView} />
              <ProtectedRoute exact path="/rooms/:id" component={Room} />
              <ProtectedRoute exact path="/rooms/:id/variant/:vid" component={Room} />
              <ProtectedRoute exact path="/products/import" component={ImportProducts} />
              <ProtectedRoute exact path="/products-logs" component={DataStatusReport} />
              <ProtectedRoute exact path="/products/:id" component={Product} />
              <ProtectedRoute exact path="/tags/:id" component={Tag} />
              <ProtectedRoute exact path="/products/clone/:id" component={CloneProduct} />
              <ProtectedRoute exact path="/design-templates" component={DesignTemplates} />
              <ProtectedRoute exact path="/design-templates/detail/:type?" component={DesignTemplatesDetail} />
              <ProtectedRoute exact path="/design-templates/detail/templates/:id" component={TemplatesDetail} />
              <ProtectedRoute exact path="/design-templates/detail/floor-plans/:id" component={FloorPlansDetail} />
              <ProtectedRoute exact path="/design-templates/detail/colors/:id" component={ColorsDetail} />
              <ProtectedRoute exact path="/design-templates/detail/textures/:id" component={TexturesDetail} />
              <ProtectedRoute exact path="/design-templates/detail/design-styles/:id" component={DesignStylesDetail} />
              <ProtectedRoute exact path="/design-templates/detail/design-templates/:id" component={DesignRulesDetail} />
              <ProtectedRoute
                exact
                path="/design-templates/detail/categories/:id"
                component={DesignTemplatesCategoriesDetail}
              />
            </Switch>
            <Switch>
              <ProtectedRoute exact path="/etl" component={ETL} />
              <ProtectedRoute exact path="/etl/scrapers" component={Scrapers} />
              <ProtectedRoute exact path="/etl/scrapers/:id/products" component={ScraperProducts} />
              <ProtectedRoute exact path="/etl/scrapers/:id/map_categories" component={ScraperMapCategories} />
              <ProtectedRoute exact path="/etl/scrapers/:id/products/:product_id" component={ScraperProduct} />
              <ProtectedRoute exact path="/etl/inventory-files/" component={InventoryFiles} />
              <ProtectedRoute exact path="/etl/inventory-files/:id/files" component={Inventories} />
              <ProtectedRoute exact path="/etl/inventory-files/:id/files/:inventory_id" component={Inventory} />
            </Switch>
            <Route exact path="/tags" component={Tags} />
            <Route exact path="/categories-rules" component={CategoryRules} />
          </Suspense>
        </section>
      </article>
    );
  }
}

export default connect(
  (state) => ({
    admin: {
      avatar:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxadr9ykSPoaet-5e7-_YZtueYaRJSvggWtEShh2EJyAjAf5-D',
      name: 'Fiona Lai',
    },
    categories: categoriesSelector(state),
    loading: loadingCategoriesSelector(state),
  }),
  {
    getCategories,
  }
)(withRouter(App));
