import styled, { css } from "styled-components"

export default styled.button`
  ${({ size }) => {
    switch (size) {
      case "small": {
        return css`
          padding: 10px;
          font-size: 12px;
          border-radius: 20px;
        `
      }
      case "large": {
        return css`
          padding: 15px;
          font-weight: 700;
          font-size: 16px;
          border-radius: 15px;
        `
      }
      default: {
        return css`
          padding: 10px;
          font-size: 14px;
          border-radius: 20px;
        `
      }
    }
  }};

  ${({ color }) => {
    switch (color) {
      case "secondary": {
        return css`
          color: #fff;
          background-color: #434343;
          border: none;

          &:hover,
          &:focus {
            background-color: #000;
          }
        `
      }
      case "tertiary": {
        return css`
          color: #434343;
          background-color: #dbdbdb;
          border: none;
          border-radius: 0;

          &:hover,
          &:focus {
            background-color: #999;
          }
        `
      }
      case "white": {
        return css`
          color: #333;
          background-color: #fff;
          border: 1px solid #616161;

          &:hover,
          &:focus {
            background-color: #dbdbdb;
          }
        `
    }

    case "warning": {
      return css`
          color: #fff;
          background-color: #e59400;
          border: none;

          &:hover,
          &:focus {
            background-color: #b27300;
          }
        `
    }

    case "danger": {
      return css`
          color: #fff;
          background-color: #ff3232;
          border: none;

          &:hover,
          &:focus {
            background-color: #b22323;
          }
        `
    }

    default: {
      return css`
          color: #fff;
          background-color: #1ab394;
          border: none;

          &:hover,
          &:focus {
            background-color: #0f6b58;
          }
        `
      }
    }
  }};

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `};
  ${({ width }) =>
    width &&
    css`
      min-width: ${width}px;
    `};

  display: inline-block;
  font-family: inherit;
  vertical-align: middle;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  transition: all 0.3s;

  &:active {
    opacity: 0.7;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    background-color: #8e8e8e;
    opacity: 0.5;
    cursor: not-allowed;
  }
`
