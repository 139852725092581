import styled from "styled-components"

export default styled.section`
  width: 100%;
  padding-top: 50px;
  
  .move__box-button{
    margin-top: 20px;
  }
  
  .move__box-select{
    margin-top: 20px;
    margin-left: 0px;
  }
  
  .move__box-label{
    margin-right: 20px;
    text-transform: none;
  }
  .move-modal-content{
    justify-content: center;
  }
`
