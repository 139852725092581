import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import {
  getMoveDesignRoomRequest, getRoomVariant,
  currentRoomVariantSelector, loadingForVariantSelector,
} from '../../../../ducks/moveDesignRoom';
import MoveFormStyle from './style';
import { Button, ModalWindow } from '../../../Shared';
import StyledComponents from '../../Variant/MoveModal/style';

class MoveForm extends Component {
  static propTypes = {
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
    isModalOpen: PropTypes.bool,
  };

  state = {
    products: this.props.products,
    roomVariant: null,
    room: this.props.room,
    room_id: this.props.room_id,
    variant_id: this.props.variant_id,
    design_room: null,
    design_room_id: this.props.design_room_id,
    isModalOpen: false,
  };

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      push('/login');
    }

    this.setState({
      isMounted: true,
    });
  }

  validate = (e) => {
    const value = Array.isArray(e) ? e[0] : e;
    const isNotValid = this.props.missingRequiredFields && !value;
    return isNotValid ? 'is-invalid' : '';
  };

  getListDesignRooms = (products) => products.map((product) => (
    { label: product.key, value: product.design_room_id }
  ));

  getCheckedProducts = (design_rooms, design_room_id) => {
    const design_room_products = design_rooms.filter((design_room) => design_room.design_room_id === design_room_id);
    const values = design_room_products.map((design_room) => design_room.values);
    const ids = values[0].filter((product) => product !== null && product.is_checked_for_move === true).map((product) => product.furniture_id);

    return ids;
  };

  getSelectedListDesignRooms = (itemsList, itemsIds = []) => itemsList.filter((item) => item.value === itemsIds);

  handleChangeDesignRoom = (target) => {
    this.setState({ value: target.value });
    this.setState({ design_room_id: target.value });
  };

  openModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleSubmitDesignRoom = (event) => {
    const { variant_id } = this.state;
    const design_room_id = this.state.value;
    const { room_id } = this.state;
    const from_design_room_id = this.props.room.design_room_id;
    const furniture_ids = this.getCheckedProducts(this.props.products, from_design_room_id);

    if (furniture_ids.length !== 0) {
      const { getMoveDesignRoomRequest } = this.props;
      const payload = {
        variant_id,
        room_id,
        design_room_id,
        from_design_room_id,
        furniture_ids,
      };
      getMoveDesignRoomRequest(payload);
      this.props.onSave();
    } else {
      this.openModal();
    }

    event.preventDefault();
  };

  render() {
    const { products } = this.props;
    const listDesignRooms = this.getListDesignRooms(products);
    const { isModalOpen } = this.state;

    return (
      <MoveFormStyle>
        <form className="container-fluid" onSubmit={this.handleSubmitDesignRoom}>
          <div className="row mb-5pl-0 move__box-select">
            <div className="col-lg-9 pl-2 flex move__box-label">
              Move to:
            </div>
            <div className="col-lg-9 pl-2 flex">
              <Select
                className="select2-single"
                options={listDesignRooms}
                onChange={this.handleChangeDesignRoom}
                value={this.getSelectedListDesignRooms(listDesignRooms, this.state.design_room_id)}
              />
            </div>
          </div>
          <div className="move__box-button">
            <Button type="submit" size="small" width={100}>
              Move Room
            </Button>
          </div>
        </form>
        {isModalOpen && (
          <ModalWindow closeModal={this.closeModal}>
            <StyledComponents>
              <div className="modal__header">
                <h1 className="modal__title">Move Products</h1>
              </div>
              <div className="row move-modal-content">
                <span className="modal__content">
                  You have not selected any products!
                </span>
              </div>
              <div className="row move-modal-content">
                <span>
                  Please select at least one product and try again.
                </span>
              </div>
              <div className="add-attributes__footer">
                <Button type="button" width={120} onClick={this.closeModal}>
                  Cancel
                </Button>
              </div>
            </StyledComponents>
          </ModalWindow>
        )}

      </MoveFormStyle>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.roomsReducer.isLoading,
  roomVariant: currentRoomVariantSelector(state),
  loadingVariant: loadingForVariantSelector(state),
});

export default connect(
  mapStateToProps,
  { getMoveDesignRoomRequest, getRoomVariant }
)(MoveForm);

MoveForm.propTypes = {
  products: PropTypes.array.isRequired,
};
